<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/JGGK' }">机构概况</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <h1 align="center" style="text-align:center;">
        国家级放射影像专业质控中心哨点医院工作制度
      </h1>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        国家级放射影像专业质控中心哨点医院工作制度
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        一、总则
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;为了规范哨点医院放射影像质控工作，提高放射影像质量，保障患者安全，制定本制度。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;本制度适用于国家级放射影像专业质控中心哨点医院。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        二、职责与权限
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;哨点医院应设立放射影像质控机构，负责放射影像质控工作的组织实施。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;哨点医院放射影像质控机构应具备专业技术人员和相应的设备、设施。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        3.&nbsp;哨点医院放射影像质控机构应定期对放射影像质量进行检查、评估和反馈，确保放射影像质量符合相关标准和规范。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        4.&nbsp;哨点医院放射影像质控机构应定期向国家级放射影像专业质控中心报告工作，接受其指导和监督。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        三、工作流程
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;哨点医院放射影像质控机构应制定详细的工作计划和方案，明确工作目标、任务和时间节点。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;哨点医院放射影像质控机构应按照工作计划和方案开展工作，确保各项任务按时完成。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        3.&nbsp;哨点医院放射影像质控机构应对放射影像质量进行检查、评估和反馈，及时发现问题并采取措施加以改进。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        4.&nbsp;哨点医院放射影像质控机构应定期向上级主管部门报告工作进展情况，接受其指导和监督。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        5.&nbsp;哨点医院放射影像质控机构应积极配合国家级放射影像专业质控中心的工作，提供必要的信息和资料。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        四、监督与考核
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;国家级放射影像专业质控中心应对哨点医院放射影像质控工作进行指导、监督和考核。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;指导、监督和考核的内容包括工作计划的执行情况、工作质量、工作效率等方面。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        3.&nbsp;对于监督和考核中发现的问题，国家级放射影像专业质控中心应及时向哨点医院反馈并要求其整改。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        4.&nbsp;对于整改不到位的哨点医院，国家级放射影像专业质控中心可采取相应的处罚措施。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        五、附则
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;本制度自发布之日起施行。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;本制度的解释权归国家级放射影像专业质控中心所有。
      </p>
      <p>
        <br />
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.wrap {
  width: 70%;
  background: #fff;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}
h1 {
  font-size: 22px;
  margin-bottom: 40px;
}
</style>