import { render, staticRenderFns } from "./YYGZZD.vue?vue&type=template&id=5937cbd9&scoped=true&"
import script from "./YYGZZD.vue?vue&type=script&lang=js&"
export * from "./YYGZZD.vue?vue&type=script&lang=js&"
import style0 from "./YYGZZD.vue?vue&type=style&index=0&id=5937cbd9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5937cbd9",
  null
  
)

export default component.exports